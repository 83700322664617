import { NavLink } from 'react-router-dom';

import { SectionTitle } from '../../components';

import './services.scss';

const Services = ({ services }) => (
  <section className="services">
    <div className="container">
      <div className="services__content">
        <SectionTitle subtitle="We Fix" title={`Appliance <span class="dashed">Services</span>`} />

        <div>
          <p>
            At Precision Appliance Repair, we provide repair services for all major household appliances. Our team is
            committed to getting your appliances up and running as quickly as possible with our same-day and emergency
            repair services.
          </p>
          <p>
            Additionally, we offer competitive pricing and will provide you with an upfront estimate before starting any
            repairs. Trust us to handle your appliance repair needs with speed, quality, and transparency.
          </p>
        </div>
      </div>

      <div className="services__list">
        {services
          .sort(function (a, b) {
            if (a.title < b.title) return -1;

            return 0;
          })
          .map((el, index) => (
            <NavLink
              key={index}
              to={`/${el.slug}`}
              className="services__list--item"
              state={{ prevLocation: window.location.pathname.split('/')[1] }}
            >
              <div className="icon">
                <img src={el.icon} alt={el.title} />
              </div>

              <h4>{el.title}</h4>
            </NavLink>
          ))}
      </div>
    </div>
  </section>
);

export default Services;
