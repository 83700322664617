import { SectionTitle } from '../../components';

import './areas.scss';

const Areas = ({ data }) => (
  <section className="areas">
    <div className="container">
      <SectionTitle subtitle="Service Area" title={`Areas <span class="dashed">We Serve</span>`} />

      <ul>
        {data?.map((location, index) => (
          <li key={index}>{location.area}</li>
        ))}
      </ul>
    </div>
  </section>
);

export default Areas;
