import { Seo, Layout } from '../components';

const LegalPageTemplate = ({ seo, title, contacts, logos, data }) => (
  <Layout logos={logos} contacts={contacts} title={title}>
    <Seo data={seo} />

    <div className="container">
      <div className="legal-content" dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  </Layout>
);

export default LegalPageTemplate;
