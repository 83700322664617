import { useState, useEffect } from 'react';

import { SectionTitle } from '../../components';

import './brands.scss';

const Brands = ({ brands }) => {
  const [slide, setSlide] = useState();
  const [buttons, setButtons] = useState({
    prev: false,
    next: true,
  });

  const onNextClick = () => {
    setSlide([...brands.logos.slice(25, brands.logos.length)]);
    setButtons({
      prev: true,
      next: false,
    });
  };

  const onPrevClick = () => {
    setSlide([...brands.logos.slice(0, 25)]);
    setButtons({
      prev: false,
      next: true,
    });
  };

  useEffect(() => {
    if (brands.logos.length) setSlide([...brands.logos.slice(0, 25)]);
  }, [brands.logos]);

  return (
    <section className="brands">
      <div className="container">
        <div className="brands__content">
          <SectionTitle subtitle="Brands" title="We Repair All Brands And Makes" />

          <div dangerouslySetInnerHTML={{ __html: brands.description }} />
        </div>

        <div className="brands__nav">
          <button onClick={onPrevClick} className="button prev" disabled={!buttons.prev} />

          <button onClick={onNextClick} className="button next" disabled={!buttons.next} />
        </div>

        <ul className="brands__slider">
          {slide?.map((el) => (
            <li key={el.ID}>
              <img src={el.url} alt={`${el.alt} Appliance Repair`} />
            </li>
          ))}
        </ul>

        <ul className="brands__list">
          {brands?.logos.map((el) => (
            <li key={el.ID}>{el.alt} Appliance Repair</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Brands;
