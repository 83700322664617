import { SectionTitle } from '../../components';

import './about.scss';

const About = ({ title, about }) => (
  <section className="about">
    <div className="container">
      <SectionTitle subtitle="About Us" title={`${title}<br/><span class="dashed">Experts</span>`} />

      <div className="about__content">
        <div className="about__content--image">
          <img src={about?.image} alt={title} />
        </div>

        <div className="about__content--text" dangerouslySetInnerHTML={{ __html: about.content }} />
      </div>
    </div>
  </section>
);

export default About;
