import { useLocation } from 'react-router-dom';

import { SectionTitle, BookingForm } from '../../components';

import './contactUs.scss';

const ContactUs = ({ services }) => {
  const location = useLocation();

  return (
    <section className="contact-us">
      <div className="container">
        <div className="content">
          <SectionTitle
            subtitle="Contact Our Specialists"
            title={`Get Your Quote <span class="dashed">Online!</span>`}
          />

          <p>Don't wait - contact us today and let us know how we can help you!</p>
        </div>
        <BookingForm services={services} preSelect={location.pathname.split('/')[1]} />
      </div>
    </section>
  );
};

export default ContactUs;
