import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from '../';

import './header.scss';

const Header = ({ phone, logo }) => {
  const [shadow, setShadow] = useState(false);

  const handleScroll = () => {
    setShadow(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="container">
        <NavLink to="/" className="page-header__logo">
          <img src={logo} alt="Precision Appliance Repair" />
        </NavLink>

        <div className="links">
          <Button href={`tel:${phone}`} text={phone} contained={false} />

          <Button href="/book-now" text="Book Now" />
        </div>
      </div>
    </header>
  );
};

export default Header;
