import { Seo, Layout } from '../components';

import {
  Hero,
  Advantages,
  ContactUs,
  About,
  Services,
  Reviews,
  CallToAction,
  Areas,
  Steps,
  Brands,
  CallUs,
} from '../sections';

const PageTemplate = ({ seo, title, contacts, services, reviews, areas, steps, brands, why_we, logos, about }) => (
  <Layout logos={logos} contacts={contacts} title={title}>
    <Seo data={seo} />
    <Hero title={title} phone={contacts.phone} />
    <Advantages data={why_we} phone={contacts.phone} />
    <ContactUs services={services} />
    <About title={title} about={about} />
    <Services services={services} />
    <Reviews reviews={reviews} />
    <CallToAction phone={contacts.phone} />
    <Areas data={areas} />
    <Steps data={steps} />
    <Brands brands={brands} />
    <CallUs phone={contacts.phone} title={title} />
  </Layout>
);

export default PageTemplate;
