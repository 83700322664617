import { Button } from '../../components';

import './hero.scss';

const Hero = ({ title, phone }) => {
  return (
    <section className="hero">
      <div className="container">
        <div className="hero__content">
          <h1>
            <span className="dashed">Precision</span>
            {` `}
            {title}
          </h1>

          <p>Family-Owned Business Since 2006</p>

          <div className="links">
            <Button href="/book-now" text="Book Now" />

            <span>
              or call us <a href={`tel:${phone}`}>{phone}</a>
            </span>
          </div>
        </div>

        <div className="hero__image" />
      </div>
    </section>
  );
};

export default Hero;
