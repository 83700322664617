import Slider from 'react-slick';

import { SectionTitle } from '../../components';

import './reviews.scss';

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ reviews }) => (
  <section className="reviews">
    <div className="container">
      <SectionTitle subtitle="Happy Customers" title={`What Our <span class="dashed">Customers</span> Say`} />

      <Slider className="reviews__list" {...sliderSettings}>
        {reviews?.map((item, index) => (
          <ReviewCard {...{ review: item, key: index }} />
        ))}
      </Slider>
    </div>
  </section>
);

export default Reviews;

const ReviewCard = ({ review }) => {
  let icon;

  switch (review.source) {
    case 'Facebook':
      icon = './assets/sm/facebook.svg';
      break;
    case 'Yelp':
      icon = './assets/sm/yelp.svg';
      break;
    default:
      icon = './assets/sm/google.svg';
      break;
  }

  return (
    <div className="reviews__list--card">
      <header>
        <div className="images">
          <img src={icon} alt={review.source} />

          <img src="./assets/stars.svg" alt={review.source} />
        </div>

        <p>{review.text}</p>
      </header>

      <footer>
        <p>{review.name}</p>
        <p>{review.location}</p>
      </footer>
    </div>
  );
};
