import { useLocation } from 'react-router-dom';

import { Layout, BookingForm, SectionTitle } from '../components';

const BookNow = ({ title, contacts, logos, services }) => {
  const location = useLocation();

  return (
    <Layout logos={logos} contacts={contacts} title={title}>
      <section className="book-now">
        <div className="container">
          <SectionTitle title="Book Now" />
          <BookingForm services={services} preSelect={location.state?.prevLocation} />
        </div>
      </section>
    </Layout>
  );
};

export default BookNow;
