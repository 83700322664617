import { SectionTitle } from '../../components';

import './steps.scss';

const Steps = ({ data }) => (
  <section className="steps">
    <div className="container">
      <div className="steps__inner">
        <div>
          <SectionTitle title={`<span class="dashed">How to get</span> our service?`} />
          <p className="subtitle">Just follow these simple steps</p>
        </div>

        <div className="steps__grid">
          {data.map((el, index) => (
            <div className="steps__grid--item" key={index}>
              <div>
                <img src={el.icon} alt={el.title} />
              </div>

              <h5>{el.title}</h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default Steps;
