import { SectionTitle, Button } from '../../components';

import './callUs.scss';

const CallUs = ({ title, phone }) => (
  <section className="call-us">
    <div className="container">
      <SectionTitle title={`${title.split(' ')[0]} broken?<br/>Let us help you!`} />

      <p>
        Don't let a broken appliance disrupt your life any longer. Trust us to provide you with reliable and efficient
        appliance repair services. Contact us today and get your life back in order!
      </p>

      <div className="call-us__link">
        <Button href={`tel:${phone}`} text={phone} />

        <span>Call us now!</span>
      </div>

      <div className="call-us__payments">
        <img src="/assets/payments/mastercard.svg" alt="mastercard" />
        <img src="/assets/payments/visa.svg" alt="visa" />
        <img src="/assets/payments/discover.svg" alt="discover" />
        <img src="/assets/payments/ae.svg" alt="ae" />
      </div>
    </div>
  </section>
);

export default CallUs;
