import { Link } from 'react-router-dom';
import { Seo, Layout } from '../components';

const ThankYou = ({ seo, title, contacts, logos }) => (
  <Layout logos={logos} contacts={contacts} title={title}>
    <Seo data={seo} />

    <div className="container">
      <div className="thank-you">
        <h2>Thank you!</h2>
        <p>We’ll respond to your request shortly!</p>

        <Link to="/">Back to Main page</Link>
      </div>
    </div>
  </Layout>
);

export default ThankYou;
