import { Link } from 'react-router-dom';
import { Seo, Layout } from '../components';

const PageNotFound = ({ seo, title, contacts, logos }) => (
  <Layout logos={logos} contacts={contacts} title={title}>
    <Seo data={seo} />

    <div className="container">
      <div className="page-not-found">
        <img src="../assets/404.svg" alt="404 error" />

        <h2>Oops!</h2>

        <p>We can’t seem to find the page you are looking for</p>

        <Link to="/">Back to Main page</Link>
      </div>
    </div>
  </Layout>
);

export default PageNotFound;
