import './sectionTitle.scss';

const SectionTitle = ({ subtitle, title }) => (
  <div className="section-title">
    {subtitle && <span>— {subtitle}</span>}

    <h2 dangerouslySetInnerHTML={{ __html: title }} />
  </div>
);

export default SectionTitle;
