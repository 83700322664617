import { SectionTitle, Button } from '../../components';

import './cta.scss';

const CallToAction = ({ phone }) => (
  <section className="cta">
    <div className="container">
      <SectionTitle title="Need Your Appliances Fixed?" />

      <div className="cta__links">
        <Button href="/book-now" text="Book Now" />

        <span>
          or call us <a href={`tel:${phone}`}>{phone}</a>
        </span>
      </div>
    </div>
  </section>
);

export default CallToAction;
