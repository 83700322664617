import { Button, SectionTitle } from '../../components';

import './advantages.scss';

const Advantages = ({ data, phone }) => (
  <section className="advantages">
    <div className="container">
      <SectionTitle subtitle="Advantages" title={`Why <span class="dashed">Precision</span> Appliance Repair?`} />

      <div className="advantages__grid">
        {data?.map((el, index) => (
          <GridCard key={index} item={el} />
        ))}

        <div>
          <div>
            <header>
              <span>Free</span>
              <div>
                <span>Service Call With Repair</span>
                <span>Always Low Cost & High Quality Service</span>
              </div>
            </header>

            <Button href="/book-now" text="Book Now" />

            <a href={`tel:${phone}`} className="phone">
              {phone}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Advantages;

const GridCard = ({ item }) => (
  <div className="advantages__grid--card">
    <div className="icon">
      <img src={item.icon} alt={item.title} />
    </div>

    <h4>{item.title}</h4>

    <p>{item.description}</p>
  </div>
);
